<template>
	<section>
		<div class="banner">
			<div class="section">
				<div class="section-content banner-con">
					<div class="top-title">注册聚码账号</div>
					<div class="top-text">
						<p>为客户提供销售增长的数字化营销解决方案</p>
						<p>致力于企业的销售增长</p>
					</div>

				</div>
			</div>
		</div>
		<div class="resgister-con">
			
			<div class="from-con">
				<p class="pro">欢迎注册聚码账号，请填写以下信息：</p>
				<el-form :model="ruleForm" :rules="rules2"
	            status-icon
	            ref="ruleForm" 
	            label-position="left" 
	            label-width="100px" class="demo-ruleForm">
	                <el-form-item label="名称：" prop="name">
					    <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
					</el-form-item>
	                <!-- <el-form-item label="登录密码：" prop="password">
	                    <el-input type="password" 
	                        v-model="ruleForm.password" 
	                        auto-complete="off" 
	                        placeholder="请输入密码"
	                    ></el-input>
	                </el-form-item>
	                <el-form-item label="确认密码：" prop="checkPass">
	                    <el-input type="password" 
	                        v-model="ruleForm.checkPass" 
	                        auto-complete="off" 
	                        placeholder="请再次输入密码"
	                    ></el-input>
	                </el-form-item> -->
	                <!-- <div v-for="(val,index) in areadata" :key="index">{{val}}哈哈{{index}}fgdfg</div> -->
	                <el-form-item label="所在城市：" required>
					    <el-col :span="11">
					      <el-form-item prop="areaid">
					        <el-select v-model="ruleForm.areaid" placeholder="请选择省份">
						      <el-option v-for="(val,index) in areadata" :key="index" :label="val" :value="index" @click.native="searchArea(index)"></el-option>
						      <!-- <el-option label="区域二" value="beijing"></el-option> -->
						    </el-select>
					      </el-form-item>
					    </el-col>
					    <el-col class="line" :span="2"> - </el-col>
					    <el-col :span="11">
					      <el-form-item prop="cityid">
					        <el-select v-model="ruleForm.cityid" placeholder="请选择市区">
						      <el-option v-for="(val,index) in citydata" :key="index" :label="val" :value="index"></el-option>
						    </el-select>
					      </el-form-item>
					    </el-col>
					</el-form-item>
					<el-form-item label="手机号码：" prop="mobile">
						 <!-- :disabled="code_in" -->
					    <el-input v-model="ruleForm.mobile" placeholder="请输入手机号码"></el-input>
					    <div style="color:#F56C6C;" v-if="registered">该账号已经注册</div>
					</el-form-item>
					<el-form-item label="短信验证：" prop="mmcode">
						<el-col :span="13">
					      <el-form-item prop="mmcode">
					        <el-input v-model="ruleForm.mmcode" placeholder="请输入验证码"></el-input>
					      </el-form-item>
					    </el-col>
					    <el-col :span="11">
					      <el-form-item class="code-b">
					        <el-button type="text" :disabled="code_in" @click="sendcode">发送短信验证码 <span v-if="code_in">{{count}}</span></el-button>
					      </el-form-item>
					    </el-col>
					</el-form-item>
	                <el-form-item style="width:100%;" class="message-box">
	                	<div class="login-code">*已认真阅读<a href="###">《使用条款》</a>与<a href="###">《隐私政策》</a></div>	
	                    <el-button type="primary" @click="handleSubmit">确认注册</el-button>
	                </el-form-item>
	                
	            </el-form>
            </div>
		</div>
	</section>
</template>
<script type="text/javascript">
	export default {
    data(){
    	// var validatePass = (rule, value, callback) => {
	    //     if (value === '') {
	    //       callback(new Error('请输入密码'));
	    //     } else {
	    //       if (this.ruleForm.checkPass !== '') {
	    //         this.$refs.ruleForm.validateField('checkPass');
	    //       }
	    //       callback();
	    //     }
	    // };
	    // var validatePass2 = (rule, value, callback) => {
	    //     if (value === '') {
	    //       callback(new Error('请再次输入密码'));
	    //     } else if (value !== this.ruleForm.password) {
	    //       callback(new Error('两次输入密码不一致!'));
	    //     } else {
	    //       callback();
	    //     }
	    // };
	    var checkPhone = (rule, value, callback) => {
		    if (!value) {
		      return callback(new Error(' '));
		    } else {
		      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
		      console.log(reg.test(value));
		      if (reg.test(value)) {
		        callback();
		      } else {
		        return callback(new Error('请输入正确的手机号码'));
		      }
		    }
		};
        return {
        	logining: true,
        	ruleForm:{
        	},
        	rules2:{
        		name: [{ required: true, message: ' ', trigger: 'blur' }],
        		// password: [{ required: true,validator: validatePass, trigger: 'blur' }],
          // 		checkPass: [{ required: true,validator: validatePass2, trigger: 'blur' }],
        		areaid: [{ required: true, message: ' ', trigger: 'change' }],

        		cityid: [{ required: true, message: ' ', trigger: 'change' }],
				mobile: [{required: true,validator: checkPhone, trigger: 'blur'}],
        		mmcode: [{ required: true, message: ' ', trigger: 'blur'}],

	      	},
        	areadata:{},
        	citydata: {},

        	code_in:false,
        	count: '',
        	timer: null,
        	registered: false
        }
    },
	mounted(){
		this.areaf(0);
	},
	methods: {
		areaf(cityid){
			let _this = this;
			this.axios.post('/index/nologin/get_areas', {
                areaid: cityid 
            })
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {

                    _this.$nextTick(function () {
                        _this.logining = false;
                        if(cityid==0){
                        	_this.areadata = res.extra;
                        }else{
                        	_this.citydata = res.extra;
                        }
                    })
                    
                } else {
                    _this.logining = false;
                    // _this.oper_con = res.message;
                    _this.$alert(res.message, 'info', {
                        confirmButtonText: 'ok'
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},
		searchArea(id){
			this.areaf(id);
		},
		// 发送验证码
		sendcode(){
			let _this = this;
			this.registered = false;
			if(this.ruleForm.mobile){
				
				this.axios.post('/index/nologin/get_mmcode_register', {
	                mobile: _this.ruleForm.mobile 
	            })
	            .then(function (msg) {
	                const res = msg.data;
	                if (!res.err) {
	                    _this.$nextTick(function () {
	                        _this.logining = false;
	                		_this.$alert('验证码已发送到指定手机号码上，请注意查收！','成功提示',{
					          confirmButtonText: '确定'
					        });
					        _this.code_in = true;

							const TIME_COUNT = 120;
					        if (!_this.timer) {
					        	_this.count = TIME_COUNT;
						       	_this.code_in = true;
						       	_this.timer = setInterval(() => {
						       	if (_this.count > 0 && _this.count <= TIME_COUNT) {
						         	_this.count--;
						        } else {
						         	_this.code_in = false;
						         	clearInterval(_this.timer);
						         	_this.timer = null;
						        }
						       }, 1000)
						    }
	                        
	                    })
	                    
	                } else {
	                	if(res.message == 'account_registered'){
	                		_this.registered = true;
	                	}
	                    _this.logining = false;
	                    // _this.oper_con = res.message;
	                    
	                }

	            })
	            .catch(function (error) {
	                console.log(error);
	            });
			}else{
				_this.$alert('请先输入有效手机号码','错误提示',{
		          confirmButtonText: '确定'
		        });
			}
			
		},
		postf(){
			let _this = this;
			this.axios.post('/index/nologin/register_form', {
                register: this.ruleForm 
            })
            .then(function (res) {
                const data = res.data;
                if (!data.err) {

                    _this.$nextTick(function () {
                        _this.logining = false;

                        _this.$router.push({name: 're_success'});
                    })
                    
                } else {
                    _this.logining = false;
                    // _this.oper_con = res.message;
                  
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},
		handleSubmit(){
            let _this = this;
            this.$refs.ruleForm.validate((valid) => {
                if(valid){
                    this.logining = true;
                    // let sha256 = require("js-sha256").sha256;
                    // let pwd1 = sha256(this.ruleForm.pass);
                    // this.ruleForm.password = pwd1;
                    _this.postf();
                }else{
                    console.log('error submit!');
                    return false;
                }
            })
        },
	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
		width: 100%;
		height: 300px;
		background-image: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/b66663.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-color: #fff;
		overflow: hidden;
		color: #333;
	}
	.banner-con{
		padding-left: 120px;
	}
    .top-title{
		font-size: 24px;
		line-height: 24px;
		font-weight: bolder;
		margin-top: 80px;
	}
	.top-text{
		font-size: 15px;
		font-weight: 300;
		padding-top: 6px;
		color: #333;
	}
	.resgister-con{
		width: 799px;
		padding: 60px;
		border: solid 1px #E5E1E1;
		border-radius: 10px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 80px;
		background-color: #fff;
	}
	.from-con{
		/*padding: 0 80px;*/
	}
	.pro{
		font-size: 14px;
		font-weight: bold;
		color: #333333;
		margin-bottom: 80px;
	}
	.code-b{
		text-align: center;
	}
	.message-box{
		text-align: right;
		margin-top: 80px;
	}
	.login-code{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-size: 12px;
		color: #717070;
	}
	.login-code a{
		color: #169BD5;
	}
	.el-button--text{
		font-weight: 400;
		color: #169BD5;
	}
	.el-form{
		width: 500px;
		margin: auto;
	}
	.el-form-item{
		margin-bottom: 30px;
	}
	.el-form-item .el-form-item{
		margin-bottom: 0;
	}
	.el-select{
		width: 100%;
	}
	/deep/.el-button--primary{
		width: 190px;
		height: 36px;
		background-color: #169BD5;
		border-radius: 5px;
		font-size: 13px;
		color: #fff;
		border-color: #169BD5;
	}
	/deep/.el-input__inner{
		border-radius: 0;
	}
	/deep/.el-button.is-disabled.el-button--text{
		color: #C0C4CC;
	}
	.line{
		text-align: center;
	}
</style>